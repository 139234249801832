import * as React from "react";
import { useForm } from "react-hook-form";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Button } from "gatsby-theme-material-ui";
import useFormRequest from '../../hooks/useFormRequest';
import { useContext } from "react";
import {LocaleContext} from "../../context/LocaleContext";

interface Input {
    name: string
    label: string;
    required: boolean;
    options?: {
        value: string | number;
        label: string;
    }[]
}
interface Props {
    title?: string;
    subtitle?: string;
    inputs: Input[];
    checkboxLabel: string;
    buttonText: string
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '25px 50px 50px 50px',
            background: theme.palette.secondary.main
        },
        input: {
            background: theme.palette.common.white,
            width: '100%',
            margin: '10px 0'
        },
        inputs: {
            display: 'flex',
            flexDirection: 'column',
            margin: '20px 0'
        },
        button: {
            padding: '10px 45px',
            marginBottom: '20px'
        },
        margin: {
            margin: '20px 0'
        },
        error: {
            color: theme.palette.error.main
        },
        success: {
            color: theme.palette.success.main
        },
        dropdownInput: {
            border: `1px solid ${theme.palette.secondary.main}`,
            "& > #dropdown-custom-input": {
                padding: "18.5px 14px",
                background: theme.palette.common.white,
                borderBottom: "none"
            },
            
        },
        selectLabel: {
            zIndex: 1,
            padding: "12px",
        },
    }),
);

const ContactForm: React.FC<Props> = ({ title, subtitle, inputs }) => {
    const classes = useStyles();
    const localeContext = useContext(LocaleContext);
    const { state: { loading, error, data }, postData } = useFormRequest();
    const [confirm, setConfirm] = React.useState(true);
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    console.log('data', data)

    React.useEffect(() => console.log(data),[data])

    const toggleConfirm = () => {
        setConfirm(prev => !prev)
    }

    const onSubmit = async data => {
        postData(data)
    }

    React.useEffect(() => {
        if (data) {
            reset()
        }
    }, [reset, data])

    return <div className={classes.root}>
        {title && <Typography variant="h3" className={classes.margin}>
            {title}
        </Typography>}
        {subtitle && <Typography variant="body1" >
            {subtitle}
        </Typography>}
        <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <div className={classes.inputs}>
                {inputs && inputs.map(input => input.options.length !== 0
                    ? <FormControl >
                        <InputLabel id="dropdown-custom-input-helper-label" className={classes.selectLabel}>{input.label}</InputLabel>
                        <Select
                            key={input.name}
                            id="dropdown-custom-input"
                            labelId="dropdown-custom-input-helper-label"
                            className={classes.dropdownInput}
                            {...register(input.name, {
                                required: {
                                    value: input.required,
                                    message: localeContext.locale === "en" ? "required field" :  "povinné pole"
                                },
                            })}
                        >
                            {input.options.map(option => <MenuItem value={option.value}>{option.label}</MenuItem>)}
                        </Select>
                    </FormControl>
                    
                    : <TextField
                    key={input.name}
                    label={input.label}
                    variant="outlined"
                    className={classes.input}
                    {...register(input.name, {
                        required: {
                            value: input.required,
                            message: localeContext.locale === "en" ? "required field" :  "povinné pole"
                        },
                        pattern: input.name === "email"
                            ? {
                            value: /\S+@\S+\.\S+/,
                            message: localeContext.locale === "en" ? "Entered value does not match email format" : "Zadaná hodnota nezodpovedá formátu e-mailu"
                            }
                            : undefined,
                    })}
                    error={Boolean(errors[input.name]?.message)}
                    helperText={errors[input.name]?.message}
                />)}

                
            </div>
            <div className={classes.margin}>
                <FormControlLabel
                    control={
                        <Checkbox aria-label="Confirm" color="primary" checked={confirm} onClick={toggleConfirm} />
                    }
                    label={
                        localeContext.locale === "en"
                            ? "I agree with the processing of my personal data by OmniCrane for the purpose of sending business messages and newsletters."
                            : "Souhlasím, aby mi OmniCrane zasílal také novinky z oblasti prodeje poháněného daty, produktové novinky a pozvánky na akce."
                    } 
                />
            </div>

            <Button variant="contained" color="primary" className={classes.button} type="submit" disabled={!confirm || loading}>{localeContext.locale === "en" ? (!loading ? 'Send' : 'Sending') : (!loading ? 'Odeslat' : 'Odesílám')}</Button>
            {
                error && <Typography variant="subtitle2" className={classes.error}>{localeContext.locale === "en" ? "Submit failed." : "Odesílání selhalo."}</Typography>
            }
            {
                data && <Typography variant="h5" className={classes.success}>
                    {localeContext.locale === "en"
                        ? "Thank you for your email, we will be contacting you shortly."
                        : "Už o Vás víme - budeme Vás kontaktovat v nejbližší době!"
                    }
                </Typography>
            }
        </form>

    </div>
}

export default ContactForm

