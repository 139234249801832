import { useReducer } from 'react'

const URL = 'https://omnicrane-web-backend.herokuapp.com/requests' // TODO: @Tomas move this to env var of gatsby build

const initialState = {
  loading: false,
  error: undefined,
  data: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_DATA_INIT':
      return {
        ...state,
        loading: true,
        error: undefined
      };
    case 'FETCH_DATA_SUCCESS':
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data
      };
    case 'FETCH_DATA_FAILURE':
      return { ...state, loading: false, error: action.payload.error };
    default:
      throw new Error();
  }
};

function useFormRequest() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const postData = async (data = {}) => {
    // Default options are marked with *
    try {
      dispatch({
        type: 'FETCH_DATA_INIT'
      })
      // don't need to wait for response from server
      fetch(URL, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow', // manual, *follow, error
        body: JSON.stringify(data)
      });
      dispatch({
        type: 'FETCH_DATA_SUCCESS',
        payload: {
          data: {}
        }
      })
    } catch (error) {
      dispatch({
        type: 'FETCH_DATA_FAILURE',
        payload: {
          error: error.message
        }
      })
    }
  }

  return {
    state,
    postData
  }
}

export default useFormRequest;







