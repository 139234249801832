import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Layout from "../components/Layout"
import Quotation from "../components/Quotation";
import ContactForm from "../components/ContactForm";
import { Grid } from "@material-ui/core";
import Section from "../components/Section";
import Seo from "../components/seo";
import { FooterProps } from "../components/Footer";
import { NavigationProps } from "../components/Header/navigation";
import Headline from "../components/Headline";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingTop: '15px',
            [theme.breakpoints.down('md')]: {
                paddingTop: '5px',
            },
        },
        headline: {
            padding: '30px 0',
        },
        margin: {
            margin: '20px 0'
        },
        markdown: {
            '& a': {
                color: '#ea1674',
                fontWeight: 'bold',
                textDecoration: 'underline',
            }
        },
    }),
);

type DataProps = {
    strapiContactUs: any
    strapiNavigation: NavigationProps
    strapiFooter: FooterProps
}

const ContactUs: React.FC<PageProps<DataProps>> = ({ data }) => {
    const classes = useStyles();
    const navigation = data.strapiNavigation;
    const footer = data.strapiFooter;
    const contactForm = data.strapiContactUs.formSection;
    const quotation = data.strapiContactUs.quotation;
    const inputs = contactForm.inputs;
    const headline = data.strapiContactUs.headline;

    return (
        <Layout navigation={navigation} footer={footer}>
            <Seo
                title={`${headline.titleBefore}`}
                description={headline.titleBefore}
            />
            <Section color="white">
                <Headline
                    titleBefore={headline.titleBefore}
                    highlighted={headline.highlighted}
                    titleAfter={headline.titleAfter}
                    text={headline.text}
                    inputLabel={headline.inputLabel}
                    inputButtonText={headline.inputButtonText}
                    badge={headline.badge}
                />
                <Grid container spacing={3} className={classes.root}>
                    <Grid item xs={false} md={3} />
                    <Grid item xs={12} md={6} id="form">
                        {contactForm && <ContactForm
                            // title={contactForm.title}
                            // subtitle={contactForm.subtitle}
                            inputs={inputs}
                            checkboxLabel={contactForm.checkboxLabel}
                            buttonText={contactForm.buttonText}
                        />}
                    </Grid>
                    <Grid item xs={false} md={3} />
                </Grid>


                <Quotation
                    text={quotation.text}
                    highlighted={quotation.highlighted}
                    profile={{
                        ...quotation.profile,
                        icon: quotation.profile.icon && quotation.profile.icon.localFile.publicURL
                    }}
                />
            </Section>
        </Layout >
    );
}

export default ContactUs


export const query = graphql`
query ContactUsPage($locale: String) {
    strapiContactUs(locale: {eq: $locale}) {
        id
        formSection {
            buttonText
            checkboxLabel
            id
            inputs {
                id
                label
                required
                name
                options {
                    id
                    label
                    value
                }
            }
            subtitle
            title
        }
        quotation {
            highlighted
            text
            profile {
                company
                id
                name
            }
        }
        headline {
            titleBefore
            text
        }
    }
    strapiNavigation(locale: {eq: $locale}) {
        locale
        navigationItem {
            title
            customLink
            NavigationSubItems {
                title
                sub_page {
                    slug
                    pageTitle
                }
                slug
            }
        }
        ContactUsButton {
            link
            text
        }
    }
  strapiFooter(locale: {eq: $locale}) {
      bottomLinks {
        id
        link
        text
      }
        locale
        columns {
            FooterColumnItem {
                text
                link
                anchor
            }
            Title
        }
        contactsSectionLinks {
            link
            text
        }
        city
        state
        zipCode
        email
        id
        identification
        mobilePhone
        street
        socialMedia {
            name
            image {
                localFile {
                    publicURL
                }
            }
            link
        }
        bottomText
        bottomHeadlineSection {
      mainLink {
        link
        text
      }
            inputButtonText
            highlighted
            inputLabel
            titleAfter
            titleBefore
        }
    }
}
`;
